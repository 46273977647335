import { useEffect, useMemo, useRef, useState } from "react";
import usePagination from "../../../hook/usePagination";
import { Pagination } from "antd";
import Service from "../../../service/sendRequest";
import ViewUser from "./view-user";
import {
    Avatar, DataGridBody, DataGridRow, DataGrid, DataGridHeader, DataGridHeaderCell, DataGridCell,
    TableCellLayout, TableColumnDefinition, createTableColumn, Button, Field, SearchBox,
    Spinner,
} from "@fluentui/react-components";
import CustomerCode from "./customer-code";
import { GoCheckCircleFill, GoCheckCircle } from "react-icons/go";
import { INITIAL_STATE_iUSER_DASHBOARD, iUserDashboard } from "../../../util/types";




export default function AdminPageUser() {
    const [optionPagination, setOptionPagination] = useState('')
    const { data, updatePagination, limit, total, skip, loading, update } = usePagination<iUserDashboard>(Service.getAdminUsers, 'id', 20, optionPagination)

    const [userSelected, setUserSelected] = useState<iUserDashboard>(INITIAL_STATE_iUSER_DASHBOARD)
    const [showCustomerCode, setShowCustomerCode] = useState(false)
    const [editField, setEditField] = useState(false)

    const [openModalCustomerCode, setOpenModalCustomerCode] = useState(false)

    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>()
    const [searchInput, setSearchInput] = useState('')
    const inputRef = useRef('')

    useEffect(() => {
        if (searchInput.length === 0 || inputRef.current === searchInput) return
        if (currentTimeout) clearTimeout(currentTimeout)

        const newTimeout = setTimeout(() => {
            setOptionPagination(() => `&search=${searchInput}`.replaceAll(' ', ''))
            inputRef.current = searchInput
        }, 1000)

        setCurrentTimeout(newTimeout)
        return () => clearTimeout(newTimeout)
    }, [searchInput])

    const onChange = (event: any) => setSearchInput(() => event.target.value || ' ')

    const columns: TableColumnDefinition<iUserDashboard>[] = useMemo(() => [
        createTableColumn<iUserDashboard>({
            columnId: 'name',
            compare: (a, b) => {
                return a.name.localeCompare(b.name)
            },
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout
                        media={
                            <Avatar
                                // aria-label={item.author.label}
                                name={item.name}
                                badge={{ icon: (item.RMA_CUSTOMER?.customer_code && item.RMA_CUSTOMER?.customer_code !== '-         ') ? <GoCheckCircleFill /> : <GoCheckCircle color="orange" /> }}
                            />
                        }
                    >
                        {item.name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<iUserDashboard>({
            columnId: 'email',
            compare: (a, b) => {
                return a.email.localeCompare(b.email)
            },
            renderHeaderCell: () => {
                return "Email";
            },
            renderCell: (item) => {
                return item.email
            },
        }),
        // createTableColumn<iUserDashboard>({
        //     columnId: 'phone',
        //     renderHeaderCell: () => {
        //         return "Phone";
        //     },
        //     renderCell: (item) => {
        //         return item.phone || '-'
        //     },
        // }),
        createTableColumn<iUserDashboard>({
            columnId: 'organization',
            renderHeaderCell: () => {
                return "Organization";
            },
            renderCell: (item) => {
                return item.organization || '-'
            },
        }),
        createTableColumn<iUserDashboard>({
            columnId: 'customer_user',
            renderHeaderCell: () => {
                return "Customer Code";
            },
            renderCell: (item) => {
                return item.RMA_CUSTOMER?.customer_code || '-'
            },
        }),
        createTableColumn<iUserDashboard>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (user) => {
                return (
                    <div className="flex gap-1">
                        <Button disabled={editField} onClick={() => {
                            setUserSelected(() => user); setShowCustomerCode(() => true); setEditField(() => true)
                        }} aria-label="Open" > View</Button >

                        <Button disabled={editField} onClick={() => {
                            setUserSelected(() => user); setOpenModalCustomerCode(() => true); setEditField(() => true)
                        }} aria-label="Open" > Customer Code</Button >
                    </div>
                );
            },
        }),
    ], [data, editField])


    if (showCustomerCode)
        return <ViewUser userSelected={userSelected} close={() => { setUserSelected(() => INITIAL_STATE_iUSER_DASHBOARD); setShowCustomerCode(() => false); setEditField(() => false) }} />


    return (
        <>
            <CustomerCode
                open={openModalCustomerCode}
                close={(validateUpdate?: boolean) => { setUserSelected(() => INITIAL_STATE_iUSER_DASHBOARD); setOpenModalCustomerCode(() => false); if (validateUpdate) update(); setEditField(() => false) }}
                userSelected={userSelected}
            />
            <header>
                <Field>
                    <SearchBox
                        value={searchInput}
                        onChange={onChange}
                        placeholder="Search by name or email or company"
                        appearance="outline"
                    />
                </Field>
            </header>
            <div className="overflow-x-hidden pb-[50px] min-h-[600px]">
                {loading ? <Spinner className="py-[100px] sm:py-[200px]" label="Loading users..." />
                    : <>
                        <DataGrid
                            items={data}
                            className="overflow-x-auto"
                            // className="mb-56"
                            selectionMode="single"
                            focusMode="composite"
                            style={{ minWidth: "550px" }}

                            columns={columns}
                            sortable
                        >
                            <DataGridHeader>
                                <DataGridRow>
                                    {({ renderHeaderCell }) => (
                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                    )}
                                </DataGridRow>
                            </DataGridHeader>
                            <DataGridBody<iUserDashboard>>
                                {({ item, rowId }) => (
                                    <DataGridRow<iUserDashboard>
                                        key={rowId}
                                        selectionCell={{ radioIndicator: { "aria-label": "Select row" } }}
                                    >
                                        {({ renderCell }) => (
                                            <DataGridCell>{renderCell(item)}</DataGridCell>
                                        )}
                                    </DataGridRow>
                                )}
                            </DataGridBody>
                        </DataGrid>
                        <Pagination
                            showSizeChanger={false}
                            onChange={(current) => updatePagination(current)}
                            className="my-5 float-end"
                            defaultPageSize={limit}
                            defaultCurrent={skip}
                            total={total}
                            pageSize={limit}
                        />
                    </>}
            </div>
        </>
    )
}
