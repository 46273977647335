import {
    Button,
    Field,
    Input,
    Title2,
} from "@fluentui/react-components";
import { iUserDashboard } from "../../../util/types";

type props = {
    close: Function
    userSelected: iUserDashboard
}

export default function ViewUser({ close, userSelected }: props) {
    return (
        <>
            <div className="w-full flex justify-between items-center">
                <Title2 className="w-full ">User Details</Title2>
                <Button onClick={() => close()} > Back  </Button>
            </div>
            <div className="grid grid-cols-2 gap-2 container pt-5 pb-[100px]">
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Full Name">
                    <Input className=" text-gray-800" value={userSelected.name ? userSelected.name.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Email">
                    <Input className=" text-gray-800" value={userSelected.email ? userSelected.email.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Phone">
                    <Input className=" text-gray-800" value={userSelected?.phone ? userSelected.phone.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Organization">
                    <Input className=" text-gray-800" value={userSelected?.organization ? userSelected.organization.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Customer Code">
                    <Input className=" text-gray-800" value={userSelected?.RMA_CUSTOMER?.customer_code ? userSelected?.RMA_CUSTOMER?.customer_code.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Fax">
                    <Input className=" text-gray-800" value={userSelected?.fax ? userSelected.fax.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Email invoices">
                    <Input className=" text-gray-800" value={userSelected?.email_invoices ? userSelected.email_invoices.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Email order">
                    <Input className=" text-gray-800" value={userSelected?.email_order ? userSelected.email_order.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Fiscal Code">
                    <Input className=" text-gray-800" value={userSelected?.vat_gst ? userSelected.vat_gst.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Fiscal Id">
                    <Input className=" text-gray-800" value={userSelected?.fiscal_id ? userSelected.fiscal_id.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Bank">
                    <Input className=" text-gray-800" value={userSelected?.bank ? userSelected.bank.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Iban">
                    <Input className=" text-gray-800" value={userSelected?.iban ? userSelected.iban.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Sdi">
                    <Input className=" text-gray-800" value={userSelected?.sdi ? userSelected.sdi.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Payment terms">
                    <Input className=" text-gray-800" value={userSelected?.payment_terms ? userSelected.payment_terms.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Transport conditions">
                    <Input className=" text-gray-800" value={userSelected?.transport_conditions ? userSelected.transport_conditions.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Forwarder">
                    <Input className=" text-gray-800" value={userSelected?.forwarder ? userSelected.forwarder.trim() : '-'} />
                </Field>
                <Title2 className="py-5 col-span-1 sm:col-span-2">Legal headquarters address</Title2>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Street Address">
                    <Input className=" text-gray-800" value={userSelected?.street_address ? userSelected.street_address.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="City">
                    <Input className=" text-gray-800" value={userSelected?.city ? userSelected.city.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Zip Code">
                    <Input className=" text-gray-800" value={userSelected?.zip_code ? userSelected.zip_code.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="State">
                    <Input className=" text-gray-800" value={userSelected?.state ? userSelected.state.trim() : '-'} />
                </Field>
                <Title2 className="py-5 col-span-1 sm:col-span-2">Administration and business head </Title2>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Street Address">
                    <Input className=" text-gray-800" value={userSelected?.street_address_1 ? userSelected.street_address_1.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="City">
                    <Input className=" text-gray-800" value={userSelected?.city_1 ? userSelected.city_1.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="Zip Code">
                    <Input className=" text-gray-800" value={userSelected?.zip_code_1 ? userSelected.zip_code_1.trim() : '-'} />
                </Field>
                <Field className="col-span-2 sm:col-span-1 w-full font-medium" label="State">
                    <Input className=" text-gray-800" value={userSelected?.state_1 ? userSelected.state_1.trim() : '-'} />
                </Field>
            </div>
        </>
    )
}