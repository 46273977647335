import { useEffect } from "react"
import Service from "../../../service/sendRequest"
import generatePdfInformation from "./generate-pdf-information-rma"
import { transformBase64ImageToBlob } from "../../../util/util"
import { Spin } from "antd"
import { iInformationPrint, iRMA } from "../../../util/types"
import { Loading3QuartersOutlined } from "@ant-design/icons"
import useFetch from "../../../hook/useFetch"


type props = {
    idPostSelected: number,
    isAdmin?: boolean

}

type responseService = {
    data?: iInformationPrint,
}

export default function ViewerPdf({ idPostSelected, isAdmin = false }: props) {
    const { data: response, loading } = useFetch<responseService | null>(isAdmin ? Service.printRMAPostAdmin : Service.printRMAPost, idPostSelected, null)

    useEffect(() => {
        handlePrint()
    }, [response])

    const handlePrint = async () => {
        if (!response?.data) return
        const blobQRCodeRMA = transformBase64ImageToBlob(response.data.rma_qr_code.image, response.data.rma_qr_code.contentType)
        const blobQRCodeSerial = response.data.serial_number_qr_code ? transformBase64ImageToBlob(response.data.serial_number_qr_code.image, response.data.serial_number_qr_code.contentType) : null
        const pdfBase64 = await generatePdfInformation({
            qrCodeRMABlob: blobQRCodeRMA,
            serialNumberQRCodeBlob: blobQRCodeSerial,
            destination: response.data.destination,
            rma: response.data.rma as iRMA,
            rma_center: response.data.rma_center,
            attachments: response.data.attachments,
            user: response.data.user
        })
        const canvas = document.getElementById('print-pdf') as HTMLElement | any
        if (canvas) canvas.src = pdfBase64
    }

    return (
        <div className="h-screen bg-slate-200 overflow-y-hidden mb-5">
            {loading ?
                <div className='h-screen flex justify-center items-center animate-pulse w-full bg-slate-300'>
                    <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 48 }} spin />} />
                </div>
                :
                <iframe title="print-rma" id='print-pdf' className={`rounded-lg z-30 w-full h-[720px] sm:h-full`} typeof="application/pdf" />
            }
        </div>
    )
}