export type iUser = {
    ID_USER: number,
    NAME: string,
    SURNAME: string,
    EMAIL: string,
    ORGANIZATION: string,
    RMA_MANAGER: number
}

export type iCenter = {
    id: number
    id_center: number
    name: string
    shipping_address?: string
    shipping_address_1?: string
    shipping_address_2?: string
    shipping_address_3?: string
    shipping_address_4?: string
    shipping_address_5?: string
    shipping_address_6?: string
    phone_1: string
    phone_2: string
    phone_3: string
}

export type iDirections = {
    id: number
    company_name: string
    address_line_1: string
    address_line_2: string
    address_line_3: string
    zip_code: string
    city: string
    province: string
    country: string
    address_code: string
    verified_destination?: boolean
    code?: string
}


export type iRMA = {
    id: number
    id_rma: number
    address_code: string
    customer_code: string
    fault_description: string
    id_rma_center: string
    id_ts_case: string
    customer_case_id?: string
    item_code: string
    item_model: string
    item_revision: string
    create_at: string
    update_at: string
    rma_status_description: string
    serial_number: string
    status: string
    id_status: number,
    destination?: iDirections
    verified_destination?: boolean
    attachments: iAttachments[]
}

export type iRmaPostViewPage = {
    id: number
    information: string
    full_description: string
    create_at: string
    update_at: string
    status: number
    user?: {
        name: string
        last_name: string
        email: string
    }
}

export type iRmaStatus = {
    id: number
    status: string
}

export type iHashStatus = { [key: string]: { id: number, status: string } }

type iFormatImage = {
    image: string,
    contentType: string
}

export type iInformationPrint = {
    rma_center: iCenter,
    rma: iRMA,
    rma_qr_code: iFormatImage
    serial_number_qr_code: iFormatImage
    destination: iDirections
    attachments: { name: string }[]
    user: {
        company: string,
        name: string,
        last_name: string,
        phone: string,
        mail: string
    }
}


export type iUserDashboard = {
    id?: number,
    name: string,
    surname: string,
    email: string,
    organization: null | string,
    phone: null | string,
    state: null | string,
    city: null | string,
    street_address: null | string,
    zip_code: null | string,
    state_1: null | string,
    city_1: null | string,
    street_address_1: null | string,
    zip_code_1: null | string,
    state_2: null | string,
    city_2: null | string,
    street_address_2: null | string,
    zip_code_2: null | string,
    vat_gst: null | string,
    fiscal_id: null | string,
    fax: null | string,
    email_order: null | string,
    email_invoices: null | string,
    bank: null | string,
    sdi: null | string,
    iban: null | string,
    payment_terms: null | string,
    transport_conditions: null | string,
    forwarder: null | string,
    RMA_CUSTOMER?: {
        id_customer: number,
        id_rma_center: number,
        customer_code: string | null,
    }
}

export const INITIAL_STATE_iUSER_DASHBOARD: iUserDashboard = {
    id: -1,
    name: '',
    surname: '',
    email: '',
    organization: '',
    phone: '',
    state: '',
    city: '',
    street_address: '',
    zip_code: '',
    state_1: '',
    city_1: '',
    street_address_1: '',
    zip_code_1: '',
    state_2: '',
    city_2: '',
    street_address_2: '',
    zip_code_2: '',
    vat_gst: '',
    fiscal_id: '',
    fax: '',
    email_order: '',
    email_invoices: '',
    bank: '',
    sdi: '',
    iban: '',
    payment_terms: '',
    transport_conditions: '',
    forwarder: '',
}

export type iDestination = {
    id: number
    address_code: string
    address_line_1: string
    address_line_2: string
    address_line_3: string
    city: string
    company_name: string
    country: string
    province: string
    zip_code: string
}



export type NotificationMessage = {
    id: number
    en: string
    de: string
    it: string
    active: boolean
    link?: string
    date_start?: Date
    date_end?: Date
}

export type iAttachments = {
    id: number;
    link: string;
    name: string;
}

export type iFormManager = {
    id?: number,
    id_rma?: number,
    id_rma_center?: number,
    customer_code?: string
    id_status: number,
    address_code: string
    fault_description: string
    id_ts_case: string
    customer_case_id?: string
    item_code: string
    item_model: string
    item_revision: string
    rma_status_description: string
    serial_number: string
    create_at?: string
    update_at?: string
    product_type?: string
    isProductSerialOld: boolean
    destination?: iDirections
    verified_destination?: boolean
    attachments: iAttachments[]
}