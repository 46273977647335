import { PDFDocument, degrees, grayscale, rgb } from "pdf-lib";
import PNG_LOGO_URL from '../../../assets/image/LOGO_EXOR.png'
import { iCenter, iDirections, iRMA } from "../../../util/types";

function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
    })
}

function insertLineBreaks(str: string, maxLength: number) {
    let result = '';
    let currentLine = '';
    str.split(' ').forEach(word => {
        if ((currentLine + word).length > maxLength) {
            result += currentLine.trim() + '<break>';
            currentLine = '';
        }
        currentLine += word + ' ';
    });
    result += currentLine.trim();
    return result;
}


type props = {
    qrCodeRMABlob: Blob,
    serialNumberQRCodeBlob: Blob | null,
    rma_center: iCenter,
    rma: iRMA,
    destination: iDirections,
    attachments: { name: string }[]
    user: {
        company: string,
        name: string,
        last_name: string,
        phone: string,
        mail: string
    }
}
export default async function generatePdfInformation(
    { qrCodeRMABlob, serialNumberQRCodeBlob, destination, rma, rma_center, attachments, user }: props
) {
    const pdfDoc = await PDFDocument.create()

    const page = pdfDoc.addPage([350, 400])
    page.setRotation(degrees(90))
    page.setSize(595.28, 841.89) // A4
    page.moveTo(20, 0);

    const pngImageBytes = await fetch(PNG_LOGO_URL).then(response => response.blob())
    const pngImage = await blobToArrayBuffer(pngImageBytes)
    const logoPng = await pdfDoc.embedPng(pngImage)
    const pngDims = logoPng.scale(0.05)

    const pngImageQrCodeRMA = await blobToArrayBuffer(qrCodeRMABlob as Blob)
    const qrCodeRMAPng = await pdfDoc.embedPng(pngImageQrCodeRMA)
    const pngDimsQrRMACode = qrCodeRMAPng.scale(0.5)

    if (serialNumberQRCodeBlob) {
        const pngImageQrCodeSerialNumber = await blobToArrayBuffer(serialNumberQRCodeBlob as Blob)
        const qrCodePngSerialNumber = await pdfDoc.embedPng(pngImageQrCodeSerialNumber)
        const pngDimsQrCodeSerialNumber = qrCodePngSerialNumber.scale(0.5)
        page.drawText('Serial Number', {
            rotate: degrees(90),
            x: 270,
            y: 120,
            size: 9
        })
        page.drawImage(qrCodePngSerialNumber, {
            x: 262,
            y: 114,
            width: pngDimsQrCodeSerialNumber.width,
            height: pngDimsQrCodeSerialNumber.height,
            rotate: degrees(90)
        })
    }


    page.drawImage(logoPng, {
        x: 50,
        y: 20,
        width: pngDims.width,
        height: pngDims.height,
        rotate: degrees(90)
    })

    // page.drawText('QRMA19', {
    //     rotate: degrees(90),
    //     x: 35,
    //     y: 360,
    //     size: 9
    // })
    // page.drawText('Rev 2.2', {
    //     rotate: degrees(90),
    //     x: 45,
    //     y: 360,
    //     size: 9,
    // })
    page.drawLine({
        start: { x: 75, y: 20 },
        end: { x: 75, y: 410 },
        thickness: 1,
        opacity: 0.5,
    })
    page.drawText(` REPAIR SHEET NO. `, {
        rotate: degrees(90),
        x: 72,
        y: 165.23625,
        size: 9
    })
    // page.drawText('Date: ', {
    //     rotate: degrees(90),
    //     x: 88,
    //     y: 22,
    //     size: 9
    // })
    page.drawText('Date: ', {
        rotate: degrees(90),
        x: 94,
        y: 22,
        size: 9
    })

    page.drawText(`${rma.create_at}`, {
        rotate: degrees(90),
        x: 94,
        y: 50,
        size: 9,
    })

    page.drawLine({
        start: { x: 105, y: 20 },
        end: { x: 105, y: 410 },
        thickness: 1,
        opacity: 0.5,
    })
    page.drawText('Company: ', {
        rotate: degrees(90),
        x: 120,
        y: 22,
        size: 9
    })
    const company = user.company || ''
    page.drawText(company.length > 39 ? company.slice(0, 39) + '...' : company,
        {
            rotate: degrees(90),
            x: 120,
            y: company.length > 28 ? 65 : 120,
            size: 9
        })
    page.drawText('Customer Name: ', {
        rotate: degrees(90),
        x: 130,
        y: 22,
        size: 9
    })
    const full_name = `${user.name} ${user.last_name}`
    page.drawText(
        full_name.length > 33 ? full_name.slice(0, 33) + '...' : full_name,
        {
            rotate: degrees(90),
            x: 130,
            y: full_name.length > 33 ? 90 : 120,
            size: 9
        })
    page.drawText('Customer Mail: ', {
        rotate: degrees(90),
        x: 140,
        y: 22,
        size: 9
    })
    const email = user.mail || ''
    page.drawText(email.length > 34.5 ?
        email.slice(0, 34.5) + '...' : email
        , {
            rotate: degrees(90),
            x: 140,
            y: email.length > 28 ? 83 : 120,
            size: 9
        })
    page.drawText('Customer Phone: ', {
        rotate: degrees(90),
        x: 150,
        y: 22,
        size: 9
    })
    const phone = user.phone || ''
    page.drawText(
        phone.length > 27 ?
            phone.slice(0, 27) + '...' : phone,
        {
            rotate: degrees(90),
            x: 150,
            y: phone.length > 23 ? 93 : 120,
            size: 9
        })
    // page.drawText('Customers shipping list: ', {
    //     rotate: degrees(90),
    //     x: 130,
    //     y: 22,
    //     size: 9
    // })
    page.drawText('RMA: ', {
        rotate: degrees(90),
        x: 170,
        y: 22,
        size: 9
    })
    page.drawText(`${rma.id_rma}`, {
        rotate: degrees(90),
        x: 170,
        y: 120,
        size: 9,
    })
    page.drawText('Model: ', {
        rotate: degrees(90),
        x: 180,
        y: 22,
        size: 9
    })
    page.drawText(rma.item_model, {
        rotate: degrees(90),
        x: 180,
        y: 120,
        size: 9
    })
    page.drawText('Customer Case ID: ', {
        rotate: degrees(90),
        x: 190,
        y: 22,
        size: 9
    })
    page.drawText(rma.customer_case_id || '', {
        rotate: degrees(90),
        x: 190,
        y: 120,
        size: 9,
    })
    page.drawText('Exor Case ID: ', {
        rotate: degrees(90),
        x: 200,
        y: 22,
        size: 9
    })
    page.drawText(`${rma.id_ts_case}`, {
        rotate: degrees(90),
        x: 200,
        y: 120,
        size: 9,
    })
    page.drawText('RMA', {
        rotate: degrees(90),
        x: 270,
        y: 35,
        size: 9
    })
    page.drawImage(qrCodeRMAPng, {
        x: 262,
        y: 25,
        width: pngDimsQrRMACode.width,
        height: pngDimsQrRMACode.height,
        rotate: degrees(90)
    })
    page.drawLine({
        start: { x: 292, y: 20 },
        end: { x: 292, y: 410 },
        thickness: 1,
        opacity: 0.5,
    })

    if (attachments.length !== 0) {
        page.drawText('Attachments', {
            rotate: degrees(90),
            x: 120,
            y: 255.945,
            size: 10
        })
        page.drawLine({
            start: { x: 122, y: 255.945 },
            end: { x: 122, y: 410 },
            thickness: 1,
            opacity: 0.5,
        })
        for (let i = 0; i < attachments.length; i++) {
            const { name } = attachments[i]
            if (!name) continue
            page.drawText(`${i + 1}. ${name.length > 33 ? `${name.slice(0, 33)}...` : name}`, {
                rotate: degrees(90),
                x: 135 + (i * 10),
                y: 255.945,
                size: 9
            })
        }
    }

    page.drawText('WARRANTY: ', { rotate: degrees(90), x: 275, y: 290, size: 9 })
    // page.drawText('YES', { rotate: degrees(90), x: 275, y: 370, size: 9 })
    page.drawRectangle({ x: 265, y: 355, width: 15, height: 45, borderWidth: 2, borderColor: grayscale(0.5), opacity: 0.5, borderOpacity: 0.75, })

    page.drawText('FAULT CODE: ', { rotate: degrees(90), x: 310, y: 290, size: 9 })
    page.drawRectangle({ x: 300, y: 355, width: 15, height: 45, borderWidth: 2, borderColor: grayscale(0.5), opacity: 0.5, borderOpacity: 0.75, })

    page.drawText('ISSUE REPORTED: ', {
        rotate: degrees(90),
        x: 310,
        y: 22,
        size: 11
    })
    page.drawRectangle({
        x: 318,
        y: 22,
        width: 70,
        height: 386,
        borderColor: rgb(0, 0, 0),
        borderWidth: .2,
    })

    const listStringSplit = insertLineBreaks(rma.fault_description, 75).split('<break>')

    for (let i = 0; i < listStringSplit.length; i++) {
        const element = listStringSplit[i]
        page.drawText(element.trim(), {
            rotate: degrees(90),
            x: 328 + (i * 12),
            y: 25,
            size: 11
        })
    }

    // page.drawText(`Deserunt cillum fugiat dolor dolore aute incididunt do nisi esse dolor ex dolor. 
    //     Veniam laborum ex eiusmod esse anim eiusmod Lorem occaecat sint fugiat do pariatur ex esse. 
    //     Consectetur id amet quis dolor et mollit aliqua minim enim sit. Non irure non anim officia
    //      enim pariatur quis ut deserunt. Dolor irure ex cillum magna ex. Cupidatat proident ex 
    //      reprehenderit culpa magna irure veniam duis. Sunt quis ad est cupidatat incididunt commodo
    //       irure. `, {
    //     rotate: degrees(90),
    //     x: 328,
    //     y: 25,
    //     size: 11
    // })

    // page.drawText('Screen detachment / bubbles', {
    //     rotate: degrees(90),
    //     x: 342,
    //     y: 22,
    //     size: 11
    // })
    page.drawText('DETAILS', {
        rotate: degrees(90),
        x: 402,
        y: 22,
        size: 9,
    })
    page.drawLine({ start: { x: 420, y: 20 }, end: { x: 420, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 435, y: 20 }, end: { x: 435, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 450, y: 20 }, end: { x: 450, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 465, y: 20 }, end: { x: 465, y: 410 }, thickness: 1, opacity: 1, })
    page.drawText('REPAIR', {
        rotate: degrees(90),
        x: 470 + 15,
        y: 22,
        size: 9
    })
    page.drawLine({ start: { x: 485 + 15, y: 20 }, end: { x: 485 + 15, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 500 + 15, y: 20 }, end: { x: 500 + 15, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 515 + 15, y: 20 }, end: { x: 515 + 15, y: 410 }, thickness: 1, opacity: 1, })
    page.drawLine({ start: { x: 530 + 15, y: 20 }, end: { x: 530 + 15, y: 410 }, thickness: 1, opacity: 1, })

    page.drawText('TESTER: ', { rotate: degrees(90), x: 565, y: 22, size: 9 })
    page.drawText('DATE: ', { rotate: degrees(90), x: 580, y: 22, size: 9 })

    // page.drawText('COST: ', { rotate: degrees(90), x: 550, y: 300, size: 9 })
    page.drawText('WORKING HOURS: ', { rotate: degrees(90), x: 580, y: 290, size: 9 })

    page.drawText('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -', {
        rotate: degrees(0),
        x: 40,
        y: 420.945,
        size: 9
    })
    page.drawImage(logoPng, {
        x: 50,
        y: 435.945,
        width: pngDims.width,
        height: pngDims.height,
        rotate: degrees(90)
    })
    page.drawText(`${rma.create_at}`, {
        rotate: degrees(90),
        x: 35,
        y: 765.945,
        size: 9,
    })

    // Fist Colum 
    page.drawText('Invoice To', {
        rotate: degrees(90),
        x: 80,
        y: 445.945,
        size: 15,
    })
    page.drawText('Company: ', {
        rotate: degrees(90),
        x: 100,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.name}`, {
        rotate: degrees(90),
        x: 100,
        y: 500.945,
        size: 9,
    })
    page.drawText('      Street: ', {
        rotate: degrees(90),
        x: 115,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_1}, ${rma_center.shipping_address_2}`, {
        rotate: degrees(90),
        x: 115,
        y: 500.945,
        size: 9,
    })
    page.drawText('         City: ', {
        rotate: degrees(90),
        x: 130,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_3}`, {
        rotate: degrees(90),
        x: 130,
        y: 500.945,
        size: 9,
    })
    page.drawText('    Region: ', {
        rotate: degrees(90),
        x: 145,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_4}`, {
        rotate: degrees(90),
        x: 145,
        y: 500.945,
        size: 9,
    })
    page.drawText(' Zip Code: ', {
        rotate: degrees(90),
        x: 160,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_5}`, {
        rotate: degrees(90),
        x: 160,
        y: 500.945,
        size: 9,
    })
    page.drawText('       State: ', {
        rotate: degrees(90),
        x: 175,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_6}`, {
        rotate: degrees(90),
        x: 175,
        y: 500.945,
        size: 9,
    })
    page.drawText('     Vat ID: ', {
        rotate: degrees(90),
        x: 190,
        y: 445.945,
        size: 11,
    })
    page.drawText(`x`, {
        rotate: degrees(90),
        x: 190,
        y: 500.945,
        size: 9,
    })

    // Second Colum 
    page.drawText('Ship To', {
        rotate: degrees(90),
        x: 80,
        y: 650.945,
        size: 15,
    })
    page.drawText('Company: ', {
        rotate: degrees(90),
        x: 100,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.name}`, {
        rotate: degrees(90),
        x: 100,
        y: 710.945,
        size: 9,
    })
    page.drawText('      Street: ', {
        rotate: degrees(90),
        x: 115,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_1}, ${rma_center.shipping_address_2}`, {
        rotate: degrees(90),
        x: 115,
        y: 710.945,
        size: 9
    })
    page.drawText('         City: ', {
        rotate: degrees(90),
        x: 130,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_3}`, {
        rotate: degrees(90),
        x: 130,
        y: 710.945,
        size: 9,
    })
    page.drawText('    Region: ', {
        rotate: degrees(90),
        x: 145,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_4}`, {
        rotate: degrees(90),
        x: 145,
        y: 710.945,
        size: 9,
    })
    page.drawText(' Zip Code: ', {
        rotate: degrees(90),
        x: 160,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_5}`, {
        rotate: degrees(90),
        x: 160,
        y: 710.945,
        size: 9,
    })
    page.drawText('       State: ', {
        rotate: degrees(90),
        x: 175,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.shipping_address_6}`, {
        rotate: degrees(90),
        x: 175,
        y: 710.945,
        size: 9,
    })
    page.drawText('Phone Number: ', {
        rotate: degrees(90),
        x: 190,
        y: 650.945,
        size: 11,
    })
    page.drawText(`${rma_center.phone_1}`, {
        rotate: degrees(90),
        x: 190,
        y: 735.945,
        size: 9,
    })

    // First Column
    page.drawText('RMA Information', {
        rotate: degrees(90),
        x: 240,
        y: 445.945,
        size: 15,
    })
    page.drawText('               RMA: ', {
        rotate: degrees(90),
        x: 280,
        y: 445.945,
        size: 11,
    })
    page.drawText(` ${rma.id_rma}`, {
        rotate: degrees(90),
        x: 280,
        y: 520.945,
        size: 9,
    })
    page.drawText('Serial Number: ', {
        rotate: degrees(90),
        x: 295,
        y: 445.945,
        size: 11,
    })
    page.drawText(` ${rma.serial_number}`, {
        rotate: degrees(90),
        x: 295,
        y: 520.945,
        size: 9,
    })
    page.drawText('      Item Code: ', {
        rotate: degrees(90),
        x: 310,
        y: 445.945,
        size: 11,
    })
    page.drawText(` ${rma.item_code || ''}`, {
        rotate: degrees(90),
        x: 310,
        y: 520.945,
        size: 9,
    })

    // Second Column
    page.drawText('RMA ', {
        rotate: degrees(90),
        x: 285,
        y: 670.945,
        size: 11,
    })
    page.drawText('QR Code: ', {
        rotate: degrees(90),
        x: 295,
        y: 670.945,
        size: 11,
    })

    page.drawImage(qrCodeRMAPng, {
        x: 318,
        y: 720.945,
        width: pngDimsQrRMACode.width,
        height: pngDimsQrRMACode.height,
        rotate: degrees(90)
    })

    page.drawLine({
        start: { x: 360, y: 445.945 },
        end: { x: 360, y: 820.945 }, thickness: 1, opacity: .5,
    })

    // First Column
    page.drawText('Return Destination', {
        rotate: degrees(90),
        x: 400,
        y: 445.945,
        size: 15,
    })
    page.drawText(' Code: ', {
        rotate: degrees(90),
        x: 430,
        y: 466.945,
        size: 11,
    })
    page.drawText(`${destination.code}`, {
        rotate: degrees(90),
        x: 430,
        y: 500.945,
        size: 9,
    })
    page.drawText(' Company: ', {
        rotate: degrees(90),
        x: 445,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.company_name}`, {
        rotate: degrees(90),
        x: 445,
        y: 500.945,
        size: 9,
    })
    page.drawText('          City: ', {
        rotate: degrees(90),
        x: 460,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.city}`, {
        rotate: degrees(90),
        x: 460,
        y: 500.945,
        size: 9,
    })
    page.drawText('Address 1: ', {
        rotate: degrees(90),
        x: 475,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.address_line_1}`, {
        rotate: degrees(90),
        x: 475,
        y: 500.945,
        size: 9,
    })
    page.drawText('Address 2: ', {
        rotate: degrees(90),
        x: 490,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.address_line_2}`, {
        rotate: degrees(90),
        x: 490,
        y: 500.945,
        size: 9,
    })
    page.drawText('Address 3: ', {
        rotate: degrees(90),
        x: 505,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.address_line_3}`, {
        rotate: degrees(90),
        x: 505,
        y: 500.945,
        size: 9,
    })
    page.drawText('  Zip Code: ', {
        rotate: degrees(90),
        x: 520,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.zip_code}`, {
        rotate: degrees(90),
        x: 520,
        y: 500.945,
        size: 9,
    })
    page.drawText('  Province: ', {
        rotate: degrees(90),
        x: 535,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.province}`, {
        rotate: degrees(90),
        x: 535,
        y: 500.945,
        size: 9,
    })
    page.drawText('   Country: ', {
        rotate: degrees(90),
        x: 550,
        y: 445.945,
        size: 11,
    })
    page.drawText(`${destination.country}`, {
        rotate: degrees(90),
        x: 550,
        y: 500.945,
        size: 9,
    })
    // page.drawText('Address Code: ', {
    //     rotate: degrees(90),
    //     x: 550,
    //     y: 445.945,
    //     size: 11,
    // })
    // page.drawText(`${destination.address_code}`, {
    //     rotate: degrees(90),
    //     x: 550,
    //     y: 520.945,
    //     size: 9,
    // })

    const base64SaveOptions = await pdfDoc.saveAsBase64({ dataUri: true })
    return base64SaveOptions
}